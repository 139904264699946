import { getMediaQueryList } from "../../utility/getMediaQueryList";

export const commonProductBoardAccordion = () => {
  const foldedElementList = document.querySelectorAll(".js-board-list");
  const openButtonWrapperList = document.querySelectorAll(".js-open-button");
  const toggleButtonList = document.querySelectorAll(".js-open-button button");

  // 全ての要素が取得できた場合のみ処理を実行
  if (foldedElementList.length > 0  && openButtonWrapperList.length > 0 && toggleButtonList.length > 0) {
    let MAX_HEIGHT = "750px";

    getMediaQueryList("md").addEventListener("change", event => {
      if (event.matches) {
        MAX_HEIGHT = "900px";
      } else {
        MAX_HEIGHT = "750px";
      }
    });

    toggleButtonList.forEach((toggleButtonItem, index) => {
      toggleButtonItem.addEventListener("click", () => {
        const btn = toggleButtonItem;
        foldedElementList.forEach((foldedElementItem, foldedElementItemIdx) => {
          if (foldedElementItemIdx === index) {
            const content = foldedElementItem as HTMLElement;
            if (!content.classList.contains("is-open")) {
              content.style.maxHeight = 4000 + "px";
              content.classList.add("is-open");

              openButtonWrapperList.forEach(openButtonWrapperItem => {
                const btnWrapper = openButtonWrapperItem as HTMLElement;
                btnWrapper.classList.add("is-open");
              });
              btn.textContent = "閉じる";
            } else {
              content.style.maxHeight = MAX_HEIGHT;
              content.classList.remove("is-open");
              openButtonWrapperList.forEach(openButtonWrapperItem => {
                const btnWrapper = openButtonWrapperItem as HTMLElement;
                btnWrapper.classList.remove("is-open");
              });
              btn.textContent = "もっと見る";
            }
          }
        });
      });
    });
  }
};
