import { Splide } from "@splidejs/splide";
import { commonProgressSlide } from "./common/commonProgressSlide";
import MicroModal from "micromodal";

export const potterRingBikePage = () => {
  const navigationHandler = () => {
    const productListLineUp = document.querySelector(
      ".p-potterringbike-lineup"
    );
    const navigation = document.querySelector(".p-potterringbike-navbar");
    const navbarLinks = document.querySelectorAll(
      ".p-potterringbike-navbar__item"
    );
    const sections = Array.from(
      document.querySelectorAll(".p-potterringbike-lineup__unit")
    );

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          const index = sections.indexOf(entry.target);

          if (entry.isIntersecting) {
            navigation.classList.add("is-visible");

            // navbarLinks.forEach(link => link.classList.remove("active"));
            navbarLinks.forEach(link => link.setAttribute("aria-current", ""));
            // navbarLinks[index].classList.add("active");
            navbarLinks[index].setAttribute("aria-current", "step");
          }
        });
      },
      { rootMargin: "-50% 0px" }
    );

    const observerListPoint = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            navigation.classList.add("is-visible");
          } else {
            navigation.classList.remove("is-visible");
          }
        });
      },
      { rootMargin: "-50% 0px" }
    );

    sections.forEach(section => observer.observe(section));

    observerListPoint.observe(productListLineUp);
  };

  const lineUpSlideHandler = () => {
    const splideTargetElementList = document.querySelectorAll(".splide");
    splideTargetElementList.forEach(splideTargetElement => {
      const targetElementId = splideTargetElement.id;
      if (!targetElementId) return;
      commonProgressSlide(targetElementId, 3500);
    });
  };

  const mvSlideHandler = () => {
    // const splideTargetElementList = document.getElementById("productListMv");
    const splide = new Splide(".p-potterringbike-mv__slide", {
      type: "fade",
      rewind: true,
      pagination: false,
      autoplay: true,
      speed: 1000,
      drag: false,
      arrows: false,
      interval: 5000
    });
    splide.mount();
  };

  MicroModal.init();

  navigationHandler();
  lineUpSlideHandler();
  mvSlideHandler();
};
