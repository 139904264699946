import Sortable from "sortablejs";

export const commonDraggableHandler = () => {
  const boardContainer = document.querySelector(".js-board-list");
  if (boardContainer === null) return;

  new Sortable.create(boardContainer, {
    handle: ".c-product-board__handle",
    animation: 150,
    easing: "cubic-bezier(1, 0, 0, 1)",

    group: "localStorage-for-sort",
    store: {
      /**
       * Get the order of elements. Called once during initialization.
       * @param   {Sortable}  sortable
       * @returns {Array}
       */
      get: sortable => {
        const order = localStorage.getItem(sortable.options.group.name);
        return order ? order.split("|") : [];
      },

      /**
       * Save the order of elements. Called onEnd (when the item is dropped).
       * @param {Sortable}  sortable
       */
      set: sortable => {
        const order = sortable.toArray();
        localStorage.setItem(sortable.options.group.name, order.join("|"));
      }
    }
  });
};
