//価格表示のフォーマットを変更
function commonChangePriceFormat(targetElement) {
  // sysRetailPriceValueクラスを持つ要素を取得
  const priceElements = document.querySelectorAll(targetElement);
  const priceElementsArr = Array.prototype.slice.call(priceElements);
  // 各要素に対してループ処理
  priceElementsArr.forEach(priceElement => {
    // 既存のテキストを取得
    let priceText = priceElement.textContent;

    // テキストを編集（数字とカンマの組み合わせにマッチするパターンで検索・置換）
    priceText = priceText.replace(
      /([0-9,]+)円（税込）/,
      "<span class='u-price-small'>¥</span>$1<span class='u-price-small u-tax'>(税込)</span>"
    );

    // 新しいテキストを設定
    priceElement.innerHTML = priceText;
  });
}

export { commonChangePriceFormat };
