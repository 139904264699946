function newsDomAdd(): void {
  // // 新しいDOM要素を作成
  // const newDiv = document.createElement("div");
  // newDiv.classList.add("sysFuncText", "c-header_h1");
  // const newDivContent = document.createElement("div");
  // newDivContent.classList.add("sysContent");
  // newDiv.appendChild(newDivContent);
  // const newParagraph = document.createElement("p");
  // newParagraph.classList.add("c-head_title");
  // newParagraph.textContent = "台風8号による配送遅延のお知らせ";
  // newDivContent.appendChild(newParagraph);
  // // #sysHeaderを取得
  // const sysHeader = document.getElementById("sysHeader");
  // // #sysHeaderの前に新しいDOM要素を挿入
  // if (sysHeader && sysHeader.parentNode) {
  //   sysHeader.parentNode.insertBefore(newDiv, sysHeader);
  // }
}

function headerFixed(): void {
  document.addEventListener("DOMContentLoaded", () => {
    const headerWrap = document.querySelector(".c-header__wrap");
    const newsTicker = document.querySelector("#newsTicker");

    if (headerWrap && newsTicker) {
      const observer = new IntersectionObserver(
        entries => {
          // 閾値を指定して、画面から完全に離れたときにcallbackを発火させます
          if (entries[0].isIntersecting) {
            // headerH1がまだ画面内にある場合、fixedクラスを削除
            headerWrap.classList.remove("fixed");
          } else {
            // headerH1が画面外にある場合、fixedクラスを付与
            headerWrap.classList.add("fixed");
          }
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0 // 要素が画面から完全に離れたときにcallbackを発火
        }
      );

      // headerH1要素を監視対象とします
      observer.observe(newsTicker);
    }
  });
}

function miniHeaderFixed(): void {
  document.addEventListener("DOMContentLoaded", () => {
    const miniHeaderWrap = document.querySelector(".c-header_sp_mini");
    const newsTicker = document.querySelector("#newsTicker");

    if (miniHeaderWrap && newsTicker) {
      const observer = new IntersectionObserver(
        entries => {
          // 閾値を指定して、画面から完全に離れたときにcallbackを発火させます
          if (entries[0].isIntersecting) {
            // headerH1がまだ画面内にある場合、fixedクラスを削除
            miniHeaderWrap.classList.remove("fixed");
          } else {
            // headerH1が画面外にある場合、fixedクラスを付与
            miniHeaderWrap.classList.add("fixed");
          }
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0 // 要素が画面から完全に離れたときにcallbackを発火
        }
      );

      // headerH1要素を監視対象とします
      observer.observe(newsTicker);
    }
  });
}


function headerNavOpen(): void {
  const navBtn = document.querySelector(".c-navBtn");
  const closeBtn = document.querySelector(".c-header_nav__closeBtn");
  const menu = document.querySelector(".c-header_nav__inner");
  const body = document.body;

  let scrollPosition = 0;

  navBtn?.addEventListener("click", () => {
    scrollPosition = window.pageYOffset;
    body.style.position = "fixed";
    body.style.top = `-${scrollPosition}px`;
    menu.classList.toggle("c-header_nav--active");
  });

  closeBtn?.addEventListener("click", () => {
    menu.classList.remove("c-header_nav--active");
    body.style.position = "";
    body.style.top = "";
    window.scrollTo(0, scrollPosition);
  });
}

function headerNavTab(): void {
  const tabs = document.querySelectorAll(".c-header_nav__tab");

  tabs?.forEach(tab => {
    tab.addEventListener("click", () => {
      tabs.forEach(t => t.classList.remove("is-active"));
      tab.classList.add("is-active");

      const target = tab.getAttribute("data-tab-target");
      const contentPanels = document.querySelectorAll(".c-header_nav__panel");
      contentPanels.forEach(panel => {
        if (panel.getAttribute("id") === target) {
          panel.classList.add("is-active");
        } else {
          panel.classList.remove("is-active");
        }
      });
    });
  });
}

export { newsDomAdd, headerFixed, headerNavOpen, headerNavTab, miniHeaderFixed };
