import Splide from "@splidejs/splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { Intersection } from "@splidejs/splide-extension-intersection";

/**
 *
 * Progress bar slider for splidejs
 * @see https://ja.splidejs.com/
 * @param {string} targetElementId
 * @param {number} slideInterval
 */

export const commonProgressSlide = (
  targetElementId: string,
  slideInterval: number = 4000
) => {
  document.addEventListener("DOMContentLoaded", function() {
    let splide = new Splide(`#${targetElementId}`, {
      type: "loop",
      perPage: 1,
      autoplay: "pause",
      interval: slideInterval,
      pauseOnHover: false,
      pauseOnFocus: false,
      intersection: {
        inView: {
          autoplay: true
        },
        outView: {
          autoplay: false
        }
      }
    }).mount({ Intersection });

    const progressBars = Array.from(
      document.querySelectorAll(`#${targetElementId} .splide__pagination__page`)
    ).map(bar => {
      const fill = document.createElement("div");
      fill.classList.add("fill");
      bar.appendChild(fill);
      return fill;
    });

    const resetProgressBars = () => {
      progressBars.forEach(bar => {
        const progressBtn = bar.closest("button");
        progressBtn.classList.remove("is-active");
        bar.style.animationName = "none"; // リセットする
        bar.style.animationDuration = slideInterval + "ms";
      });
    };

    const startProgressBar = (newIndex: number, fromAutoplay = false) => {
      resetProgressBars();

      progressBars[newIndex].closest("button").classList.add("is-active");
      progressBars[newIndex].style.animationName = "progress";
    };

    const changeStatusClass = currentIndex => {
      const indicators = document.querySelectorAll(
        `#${targetElementId} .splide__pagination__page`
      );
      indicators.forEach((el, index) => {
        currentIndex > index && el.classList.add("is-completed"); // 現在のインデックス以前のものには「.is-completed」を付与
        currentIndex < index && el.classList.remove("is-completed"); // 現在のインデックス以降のものは「.is-completed」を削除
      });
    };

    splide.on("move", newIndex => {
      changeStatusClass(newIndex);
      startProgressBar(newIndex);
    });

    // startProgressBar(0);

    const colorBalls = document.querySelectorAll(
      `#${targetElementId} + .p-potterringbike-lineup__body .js-color-ball`
    );

    colorBalls.forEach((ball, index) => {
      ball.addEventListener("click", () => {
        splide.go(index);
      });
    });

    let lastIndex = 0;
    splide.on("moved", newIndex => (lastIndex = newIndex));
    splide.on("intersection:in", function(entry) {
      if (entry.isIntersecting) {
        startProgressBar(lastIndex);
      }
    });

    splide.on("intersection:out", () => {
      resetProgressBars();
      changeStatusClass(lastIndex);
    });
  });
};
