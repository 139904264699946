import Splide from "@splidejs/splide";
import "@splidejs/splide/css";

function gallerySlider(): void {  
  if (document.getElementById('gallery-modal')) {
      const gallery = document.getElementById('gallery');
      const modal = document.getElementById('gallery-modal') as HTMLElement;
      const overlay = document.querySelector('.overlay') as HTMLElement;
      const closeButton = document.querySelector('.close-button');
    
      // Splideの初期化
      const splide = new Splide('#gallery-modal', {
        type: 'loop',      
        pagination: false
      }).mount();
    
      // 画像をクリックするとモーダルを表示
      gallery.addEventListener('click', (e: Event) => {
        const target = e.target as HTMLElement;
        if (target.tagName === 'IMG') {
            modal.style.display = 'block';
            overlay.style.display = 'block';
    
            // ここでスライドを移動する
            const index = parseInt(target.dataset.index!, 10);
            splide.go(index);
        }
    });
    
      // CLOSEボタンかオーバーレイをクリックするとモーダルとオーバーレイを非表示
      const closeModal = () => {
        modal.style.display = 'none';
        overlay.style.display = 'none';
      };
      closeButton.addEventListener('click', closeModal);
      overlay.addEventListener('click', closeModal);
    }
  }


export { gallerySlider };

