//日付のフォーマットを変更する関数

export const formatDate: (dateString: string) => string = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed in JS
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}.${month}.${day}`;
}

