import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
// import App from "./App";
import TopicComponent from "./components/TopicComponent";
import NewsComponent from "./components/NewsComponent";
import MagazineComponent from "./components/MagazineComponent";
import EventComponent from "./components/EventComponent";
import RentalComponent from "./components/RentalComponent";
import MediaComponent from "./components/MediaComponent";
import NewsTickerComponent from "./components/NewsTickerComponent";
import HeaderMagazineComponent from "./components/HeaderMagazineComponent";
import KickBoardNewsComponent from "./components/KickBoardNewsComponent";
import KickBoardMediaComponent from "./components/KickBoardMediaComponent";

const TopicTarget = document.getElementById("topic");
const NewsTarget = document.getElementById("news");
const MagazineTarget = document.getElementById("magazine");
const EventTarget = document.getElementById("event");
const MediaTarget = document.getElementById("media");
const RentalTarget = document.getElementById("rental");
const HeaderMagazineTarget = document.getElementById("headerMagazine");
const KickBoardTarget = document.getElementById("p-kickboard-news");
const KickBoardMediaTarget = document.getElementById("p-kickboard-media");

if (TopicTarget !== null) {
  const app = ReactDOM.createRoot(TopicTarget);
  app.render(
    <StrictMode>
      <TopicComponent />
    </StrictMode>
  );
}

if (NewsTarget !== null) {
  const app = ReactDOM.createRoot(NewsTarget);
  app.render(
    <StrictMode>
      <NewsComponent />
    </StrictMode>
  );
}


if (MagazineTarget !== null) {
  const app = ReactDOM.createRoot(MagazineTarget);
  app.render(
    <StrictMode>
      <MagazineComponent />
    </StrictMode>
  );
}

if (HeaderMagazineTarget !== null) {
  const app = ReactDOM.createRoot(HeaderMagazineTarget);
  app.render(
    <StrictMode>
      <HeaderMagazineComponent />
    </StrictMode>
  );
}

if (EventTarget !== null) {
  const app = ReactDOM.createRoot(EventTarget);
  app.render(
    <StrictMode>
      <EventComponent />
    </StrictMode>
  );
}

if (RentalTarget !== null) {
  const app = ReactDOM.createRoot(RentalTarget);
  app.render(
    <StrictMode>
      <RentalComponent />
    </StrictMode>
  );
}

if (MediaTarget !== null) {
  const app = ReactDOM.createRoot(MediaTarget);
  app.render(
    <StrictMode>
      <MediaComponent />
    </StrictMode>
  );
}

function createNewsTickerContainer() {
  // 新しいDOM要素を作成
  const newsTickerContainer = document.createElement("div");
  newsTickerContainer.id = "newsTicker";

  // #sysHeaderを取得
  const sysHeader = document.getElementById("sysHeader");

  // #sysHeaderの前に新しいDOM要素を挿入
  if (sysHeader && sysHeader.parentNode) {
    sysHeader.parentNode.insertBefore(newsTickerContainer, sysHeader);
  }
}

createNewsTickerContainer();

const newsTickerContainer = document.getElementById('newsTicker');

if (newsTickerContainer !== null) {
  const app = ReactDOM.createRoot(newsTickerContainer);
  app.render(
    <StrictMode>
      <NewsTickerComponent />
    </StrictMode>
  );
}

if (KickBoardTarget !== null) {
  const app = ReactDOM.createRoot(KickBoardTarget);
  app.render(
    <StrictMode>
      <KickBoardNewsComponent />
    </StrictMode>
  );
}

if (KickBoardMediaTarget !== null) {
  const app = ReactDOM.createRoot(KickBoardMediaTarget);
  app.render(
    <StrictMode>
      <KickBoardMediaComponent />
    </StrictMode>
  );
}





