import React from "react";

// api
import { getProductData } from "../api/getArticleData";
import { formatDate } from "../utils/formatDate";

function TopicComponent() {
  const api_detail =
    "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/news?_embed";
  const { data } = getProductData(api_detail);

  // 'post.topics === '1'' に一致する記事をフィルタリングし、上位4つの記事を取得
  const filteredTopPosts = data.filter(post => post.topics === '1').slice(0, 4);

  return (
    <div>
      <div className="p-top__section">
        <div className="p-top-topic__wrapper">
          <h2 className="p-top__headingLv2">TOPICS</h2>
          <ul className="c-blog__row1-list">
            {filteredTopPosts.map((post, index) => (
              <li key={index} className="c-blog__item">
                <a className="c-blog__link" href={post.link}>
                  <picture className="c-blog__img">
                    {post.list_thumbnail 
                      ? <img src={post.list_thumbnail} alt={post.title.rendered} width="150" height="150" />
                      : <img src={"https://mobility.daytona.co.jp/img/wp/noimage.jpg"} alt={post.title.rendered} width="150" height="150" />
                    }
                  </picture>
                  <div className="c-blog__text-wrapper">
                    <p className="c-blog__text-sub">{post._embedded?.['wp:term']?.[0]?.[0]?.name}</p>
                    <p className="c-blog__text">{post.title.rendered}</p>
                    <span className="c-blog__date">{formatDate(post.date)}</span>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TopicComponent;
