function commonFaqAccordion() {
  const trigger = document.querySelectorAll(".js-accordion-trigger-faq");
  const triggerArr = Array.prototype.slice.call(trigger);
  for (let i = 0; i < triggerArr.length; i++) {
    triggerArr[i].addEventListener("click", function() {
      const parent = this.parentNode;
      const body = parent.nextElementSibling;
      if (this.classList.contains("is-accordion-active")) {
        this.classList.remove("is-accordion-active");
        this.setAttribute("title", "メニューを開く");
        this.setAttribute("aria-selected", false);
        this.setAttribute("aria-expanded", false);
        body.classList.remove("is-active");
        body.setAttribute("aria-hidden", true);
        body.setAttribute("tabindex", -1);
      } else {
        this.classList.add("is-accordion-active");
        this.setAttribute("title", "メニューを閉じる");
        this.setAttribute("aria-selected", true);
        this.setAttribute("aria-expanded", true);
        body.classList.add("is-active");
        body.setAttribute("aria-hidden", false);
        body.setAttribute("tabindex", 0);
      }
    });
  }
}

export { commonFaqAccordion };