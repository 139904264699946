import React from "react";

// api
import { getProductData } from "../api/getArticleData";
import { formatDate } from "../utils/formatDate";

function EventComponent() {
  const api_detail =
    "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/event?_embed";
  const { data } = getProductData(api_detail);
  // console.log(data, "data");

  const isPastDate = (dateString) => {
    const currentDate = new Date();
    const eventDate = new Date(dateString);
    return eventDate < currentDate;
  };

  return (
    <div>
      <div className="p-top__section c-anchor-margin" id="item3">
        <div className="p-top-event__wrapper">
          <h2 className="p-top__headingLv2">イベント情報</h2>
          <ul className="c-blog__row1-list">
            {data.slice(0, 4).map((post, index) => (
              <li key={index} className="c-blog__item">
                <a className="c-blog__link" href={post.link}>
                  <picture className={isPastDate(post.end_date) ? "c-blog__img is-end" : "c-blog__img"}>
                    {/* <img src={post._embedded?.['wp:featuredmedia']?.[0]?.media_details?.sizes?.thumbnail?.source_url} alt={post.title.rendered} width="150" height="150" /> */}
                    {post.list_thumbnail 
                      ? <img src={post.list_thumbnail} alt={post.title.rendered} width="150" height="150" />
                      : <img src={"https://mobility.daytona.co.jp/img/wp/noimage.jpg"} alt={post.title.rendered} width="150" height="150" />
                    }
                  </picture>
                  <div className="c-blog__text-wrapper">
                    <p className="c-blog__text-sub">{post._embedded?.['wp:term']?.[0]?.[0]?.name}</p>
                    <p className="c-blog__text">{post.title.rendered}</p>
                    {/* <span className="c-blog__date">{formatDate(post.date)}</span> */}
                    <div className="c-blog__date"><span>開催日</span>{post.event_date}</div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
          <div className="c-btn-wrapper">
						<a href="/contents/event/" className="c-btn">イベント情報をもっと見る</a>
					</div>
        </div>
      </div>
    </div>
  );
}

export default EventComponent;
