import Splide from "@splidejs/splide";
import { Intersection } from "@splidejs/splide-extension-intersection";
import "@splidejs/splide/css";
import { getMediaQueryList } from "../utility/getMediaQueryList";

//補修品一覧の時、bodyへクラス追加
function addClassToBodyForRepairProduct() {
  // Get the element with class 'js-product-type'
  const productType = document.querySelector(".js-product-type");

  // If the element does not exist, stop the function
  if (!productType) return;

  // Get the product type
  const type = productType.getAttribute("data-product-type");

  // Check if the product type is 'repair'
  if (type === "repair") {
    // Add the class to the body
    document.body.classList.add("is-repair");
  }
}

//リストエリア移動
function moveSearchList() {
  const targetElement = document.querySelector(".p-category__search");
  const listElement = document.querySelector(".p-category__search-list-block");

  if (!targetElement || !listElement) return;

  targetElement.appendChild(listElement);
}

//リストナビ
function refactorListNav(): void {
  // Get all the 'li' elements with class 'sysImage'
  const imageElements = document.querySelectorAll(
    ".p-category__search-model-num .sysImage"
  );

  // If there are no elements found, stop the function
  if (imageElements.length === 0) return;

  // Loop over each 'li' element
  imageElements.forEach(function(element) {
    // Get the 'img' element
    const imgElement = element.querySelector("img");

    // If the 'img' element exists
    if (imgElement) {
      // Get the alt text
      const altText = imgElement.getAttribute("alt");

      // Create a new paragraph element
      const pElement = document.createElement("p");

      // Parse altText as HTML and append to the 'p' element
      pElement.innerHTML = altText;

      // Clear the alt attribute of the 'img' element
      imgElement.setAttribute("alt", "");

      // Create a new 'div' element
      const divElement = document.createElement("div");
      divElement.classList.add("p-category__search-model-num-img-wrap");

      // Append the 'img' element to the 'div' element
      divElement.appendChild(imgElement);

      // Get the 'a' element
      const aElement = element.querySelector("a");

      // Clear the 'a' element
      while (aElement.firstChild) {
        aElement.removeChild(aElement.firstChild);
      }

      // Append the 'div' element and the 'p' element to the 'a' element
      aElement.appendChild(divElement);
      aElement.appendChild(pElement);
    }
  });
}

//リストナビ
function convertImageAltToParagraph(target): void {
  // Get all the 'li' elements with class 'sysImage'
  const imageElements = document.querySelectorAll(target);
  console.log(imageElements.length);
  // If there are no elements found, stop the function
  if (imageElements.length === 0) return;

  // Loop over each 'li' element
  imageElements.forEach(function(element) {
    // Get the 'img' element
    const imgElement = element.querySelector("img");

    // If the 'img' element exists
    if (imgElement) {
      // Get the alt text
      const altText = imgElement.getAttribute("alt");

      // Create a new paragraph element
      const pElement = document.createElement("p");
      pElement.classList.add("c-list-nav__text");

      // Parse altText as HTML and append to the 'p' element
      pElement.innerHTML = altText;

      // Clear the alt attribute of the 'img' element
      imgElement.setAttribute("alt", "");

      // Create a new 'div' element
      const divElement = document.createElement("div");
      divElement.classList.add("c-list-nav__img-wrap");

      // Append the 'img' element to the 'div' element
      divElement.appendChild(imgElement);

      // Get the 'a' element
      const aElement = element.querySelector("a");
      aElement.classList.add("c-list-nav__link");

      // Clear the 'a' element
      while (aElement.firstChild) {
        aElement.removeChild(aElement.firstChild);
      }

      // Append the 'div' element and the 'p' element to the 'a' element
      aElement.appendChild(divElement);
      aElement.appendChild(pElement);
    }
  });
}

//静的絞り込みエリア
function categoryTabChange(): void {
  const tabList = document.querySelector(".js-tab-list");

  if (!tabList) return;

  const activeIndicator = document.createElement("li");
  activeIndicator.classList.add("active-indicator");
  tabList.appendChild(activeIndicator);

  // Get all the tabs and contents
  const tabs = Array.from(document.querySelectorAll(".js-tab-button"));
  const contents = Array.from(document.querySelectorAll(".js-tab-content"));

  // Function to deactivate all tabs and hide all contents
  function resetTabs() {
    tabs.forEach(tab => tab.classList.remove("is-active"));
    contents.forEach(content => content.classList.remove("is-active"));
  }

  // Add click event listeners to tabs
  tabs.forEach(tab => {
    tab.addEventListener("click", () => {
      // Deactivate all tabs and hide all contents
      resetTabs();

      // Get the data-index of clicked tab
      const dataIndex = tab.getAttribute("data-index");

      // Activate clicked tab
      tab.classList.add("is-active");

      // Show content corresponding to clicked tab
      const content = document.querySelector(
        `.js-tab-content[data-index="${dataIndex}"]`
      );
      if (content) content.classList.add("is-active");

      // Update the position and width of the active indicator
      const rect = tab.getBoundingClientRect();
      let leftOffset = rect.left - tabList.getBoundingClientRect().left;
      let widthOffset = rect.width;

      // Shift the active indicator
      if (dataIndex === "1") {
        leftOffset += 4;
        widthOffset -= 4;
      } else if (dataIndex === "3") {
        widthOffset -= 4;
      }

      activeIndicator.style.left = `${leftOffset}px`;
      activeIndicator.style.width = `${widthOffset}px`;
    });

    // Initialize the position and width of the active indicator
    const initialActiveTab = document.querySelector(".js-tab-button.is-active");
    if (initialActiveTab) {
      const rect = initialActiveTab.getBoundingClientRect();
      let leftOffset = rect.left - tabList.getBoundingClientRect().left;
      let widthOffset = rect.width;

      // Shift the active indicator
      const dataIndex = initialActiveTab.getAttribute("data-index");
      if (dataIndex === "1") {
        leftOffset += 4;
        widthOffset -= 4;
      } else if (dataIndex === "3") {
        widthOffset -= 4;
      }

      activeIndicator.style.left = `${leftOffset}px`;
      activeIndicator.style.width = `${widthOffset}px`;
    }
  });
}

//カスタマイズスライダー
function customSlider(): void {
  const slider = document.querySelector(".js-custom-slide");

  if (!slider) return;
  const slideCount = slider.querySelectorAll(".splide__slide").length;

  var customSlide = new Splide(".js-custom-slide", {
    type: "loop",
    pagination: false,
    pauseOnHover: false,
    autoplay: true,
    // autoplay: false,
    arrows: slideCount > 1,
    pauseOnFocus: false,
    Intersection: {
      inView: {
        autoplay: true
      },
      outView: {
        autoplay: false
      }
    }
  });

  customSlide.mount();
}

//機種で絞り込むスライダー(PC)
let categorySearchSlide;

function categorySearchSlider(): void {
  const slider = document.querySelector(".js-category-search-slide");

  if (!slider) return;

  var categorySearchSlide = new Splide(".js-category-search-slide", {
    pagination: false,
    pauseOnHover: false,
    autoplay: false,
    destroy: false,
    perPage: 5,
    breakpoints: {
      768: {
        destroy: true // 500px未満でスライダー開始
      }
    }
  });

  categorySearchSlide.mount();
}
function categorySearchSliderMediaQuery(): void {
  getMediaQueryList("md").addEventListener("change", event => {
    if (!event.matches) {
      categorySearchSlider();
    }
  });
}

//商品名の移動
function moveProductName(): void {
  // liタグの要素をすべて取得
  const listItemElements = document.querySelectorAll(".sysItemList li");

  listItemElements?.forEach(element => {
    // name="price_name"のinput要素とsysItemNameクラスの要素を取得
    const priceNameInput = element.querySelector('input[name="price_name"]');
    const itemNameElement = element.querySelector(".sysItemName");

    // ノードを移動させる
    if (priceNameInput && itemNameElement) {
      priceNameInput.parentNode.insertBefore(itemNameElement, priceNameInput);
    }
  });
}

//補修品カテゴリバナー移動
function moveBannerElements() {
  // 移動したい要素を取得
  var bannerSp = document.querySelector(".c-banner-potteringbike-repair-sp");
  var bannerPc = document.querySelector(".c-banner-potteringbike-repair-pc");
  var target = document.querySelector(".p-category__banner");

  if (!bannerSp || !bannerPc) return;

  // 新たな親要素にbannerSpとbannerPcを追加
  target.appendChild(bannerSp);
  target.appendChild(bannerPc);
}

//動的絞り込みのDOM操作
function wrapElementsByClass() {
  // div要素を取得
  let divElement = document.querySelector(".sysItemCategoryCtl");

  if (!divElement) return;

  // 元のHTMLを取得
  let originalHTML = divElement.innerHTML;

  // <br>タグでHTMLを分割
  let parts = originalHTML.split("<br>");

  // 新しいHTMLを作成
  let newHTML = `<div class="p-category__form-category u-no-scroll-bar">${parts[0]}</div><div class="p-category__form-order u-no-scroll-bar">${parts[1]}</div>`;

  // 新しいHTMLをdiv要素に戻す
  divElement.innerHTML = newHTML;
}

//お気に入りボタン移動
function insertWishlistButtonIntoThumbnail() {
  //
  // liタグの要素をすべて取得
  const listItemElements = document.querySelectorAll("li[data-item-id]");

  listItemElements?.forEach(element => {
    // sysWishListButtonFormAjaxクラスとsysThumbnailImageクラスの要素を取得
    const wishListButtonElement = element.querySelector(
      ".sysWishListButtonFormAjax"
    );
    const thumbnailImageElement = element.querySelector(".sysThumbnailImage");

    // ノードを移動させる
    if (wishListButtonElement && thumbnailImageElement) {
      thumbnailImageElement.appendChild(wishListButtonElement.cloneNode(true));
      wishListButtonElement.remove();
    }
  });
}

//静的検索エリアdivでwrap
function wrapSearchElements() {
  // 要素BとCを取得
  var targetModel = document.querySelector(".p-category__search-acc-model");
  var targetCategory = document.querySelector(
    ".p-category__search-acc-category"
  );

  if (!targetModel || !targetCategory) return;

  // 新たな親要素を作成し、'p-category__search-wrap'クラスを付与
  var newDiv = document.createElement("div");
  newDiv.classList.add("p-category__search-wrap");

  // 新たな親要素にBとCを追加
  newDiv.appendChild(targetModel.cloneNode(true));
  newDiv.appendChild(targetCategory.cloneNode(true));

  // 元のBとCを新たな親要素で置き換える
  targetModel.replaceWith(newDiv);
  targetCategory.remove();
}

export {
  addClassToBodyForRepairProduct,
  moveSearchList,
  refactorListNav,
  convertImageAltToParagraph,
  categoryTabChange,
  customSlider,
  categorySearchSlider,
  categorySearchSliderMediaQuery,
  moveProductName,
  moveBannerElements,
  wrapElementsByClass,
  insertWishlistButtonIntoThumbnail,
  wrapSearchElements
};
