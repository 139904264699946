import React from "react";

// api
import { getProductData } from "../api/getArticleData";
import { formatDate } from "../utils/formatDate";

function NewsComponent() {
  const api_detail =
    "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/news?_embed";
  const { data } = getProductData(api_detail);

  return (
    <div>
      <div className="p-top__section">
        <div className="p-top-topic__wrapper">
          <h2 className="p-top__headingLv2">NEWS </h2>
          <ul className="c-blog__row1-list">
            {data.slice(0, 4).map((post, index) => {
              // Here we check if the TOPICS value is 1 before rendering
              if (post.topics === '1' || post.topics === '0') {
                return (
                  <li key={index} className="c-blog__item">
                    <a className="c-blog__link" href={post.link}>
											<picture className="c-blog__img">
												{post.list_thumbnail 
													? <img src={post.list_thumbnail} alt={post.title.rendered} width="150" height="150" />
													: <img src={"https://mobility.daytona.co.jp/img/wp/noimage.jpg"} alt={post.title.rendered} width="150" height="150" />
												}
											</picture>
                      <div className="c-blog__text-wrapper">
                        <p className="c-blog__text-sub">{post._embedded?.['wp:term']?.[0]?.[0]?.name}</p>
                        <p className="c-blog__text">{post.title.rendered}</p>
                        <span className="c-blog__date">{formatDate(post.date)}</span>
                      </div>
                    </a>
                  </li>
                )
              }
            })}
          </ul>
          <div className="c-btn-wrapper">
						<a href="/contents/news/" className="c-btn">お知らせをもっと見る</a>
					</div>
        </div>
      </div>
    </div>
  );
}

export default NewsComponent;

