import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

// api
import { getProductData } from "../api/getArticleData";

function NewsTickerComponent() {
  const api_detail =
    "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/pages/?slug=newsticker";
  const { data, isLoading } = getProductData(api_detail);


  if(isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div>
      <div className="sysFuncText c-header_h1">
        <div className="sysContent">
          <Splide options={{ type: 'loop', autoplay: true, arrows: false, pagination: false }}>
            {data[0]?.newsarea?.map((newsItem, index) => (
              <SplideSlide key={index}>
                {
                  newsItem.news_url ? (
                    <a href={newsItem.news_url} className="c-head_link">
                      <p className="c-head_title">{newsItem.news_text}</p>
                    </a>
                  ) : (
                    <div className="c-head_link">
                      <p className="c-head_title">{newsItem.news_text}</p>
                    </div>
                  )
                }
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </div>
    </div>
  );
  
}

export default NewsTickerComponent;
