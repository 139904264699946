import React from "react";

// api
import { getProductData } from "../api/getArticleData";
import { formatDate } from "../utils/formatDate";

function KickBoardNewsComponent() {
  const api_detail =
    "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/news?_embed";
  const { data } = getProductData(api_detail);

   // キックボードの記事のみをフィルタリング
   const kickboardArticles = data.filter(post => post.news_term_names.includes("キックボード"));

   return (
     <div>     
       <div className="p-top__section">
         <div className="p-top-topic__wrapper u-mt50">
           <h2 className="p-top__headingLv2">NEWS
             {/* <span>電動キックボードに関しての最新記事を掲載しています</span> */}
           </h2>
           <ul className="c-blog__row1-list">
             {kickboardArticles.slice(0, 4).map((post, index) => (
               <li key={index} className="c-blog__item">
                 <a className="c-blog__link" href={post.link}>
                   <picture className="c-blog__img">
                     {post.list_thumbnail 
                       ? <img src={post.list_thumbnail} alt={post.title.rendered} width="150" height="150" />
                       : <img src={"https://mobility.daytona.co.jp/img/wp/noimage.jpg"} alt={post.title.rendered} width="150" height="150" />
                     }
                   </picture>
                   <div className="c-blog__text-wrapper">
                      <p className="c-blog__text">{post.title.rendered} </p>
                   </div>
                 </a>
               </li>
             ))}
           </ul>           
         </div>            
       </div>
     </div>
   );
 }
 
 export default KickBoardNewsComponent;

