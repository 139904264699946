/**
 * エントリーポイントファイル
 */

// 主要ライブラリインポート例
// import * as $ from "jquery";

// web-vitalsインポート

// Namespace（プロジェクト開始時には以下ファイルを適宜削除もしくは移動させて利用してください）
// Commonインポート
import { commonMenu } from "./namespace/common/commonMenu";
import { commonProductBoardAccordion } from "./namespace/common/commonProductBoardAccordion";
import { commonSmoothScroll } from "./namespace/common/commonSmoothScroll";
import { commonTargetBlank } from "./namespace/common/commonTargetBlank";
import { commonChangePriceFormat } from "./namespace/common/commonChangePriceFormat";
import { commonFaqAccordion } from "./namespace/common/commonFaqAccordion";
import MicroModal from "micromodal";

// import initializeDraggableBoards from "./namespace/common/commonDraggable";

// import { commonProductBoardqAccordion } from "./namespace/common/commonProductBoardAccordion";
import {
  newsDomAdd,
  headerFixed,
  headerNavOpen,
  headerNavTab,
  miniHeaderFixed
} from "./namespace/common/header";
import { potterRingBikePage } from "./namespace/potterRingBike";
// Topインポート
import {
  commonAccordion,
  topSlider,
  productTabChange,
  topColorListData,
  topAboutAutoSlider
} from "./namespace/top";
// Categoryインポート
import {
  addClassToBodyForRepairProduct,
  moveSearchList,
  refactorListNav,
  convertImageAltToParagraph,
  categoryTabChange,
  customSlider,
  categorySearchSliderMediaQuery,
  categorySearchSlider,
  moveProductName,
  moveBannerElements,
  wrapElementsByClass,
  insertWishlistButtonIntoThumbnail,
  wrapSearchElements
} from "./namespace/category";

// Teaserインポート

import { gallerySlider } from "./namespace/teaser";

import { kickBoardPage } from "./namespace/kickboard";

commonMenu();
// commonSmoothScroll();

commonTargetBlank();
newsDomAdd();
headerFixed();
headerNavOpen();
headerNavTab();
miniHeaderFixed();
commonAccordion();
commonProductBoardAccordion();
commonDraggableHandler();
topAboutAutoSlider();
gallerySlider();
MicroModal.init();

// initializeDraggableBoards();
commonFaqAccordion();
// Top処理実行
if (document.getElementById("top")) {
  topColorListData();
  topSlider();
  productTabChange();
}

// 一覧（動的）ページ 処理実行
if (document.getElementById("sysItemCategory")) {
  addClassToBodyForRepairProduct();
  moveSearchList();
  refactorListNav();
  convertImageAltToParagraph(".p-category__search-acc-model .sysImage");
  convertImageAltToParagraph(".p-category__search-acc-category .sysImage");
  categoryTabChange();
  customSlider();
  categorySearchSliderMediaQuery();
  moveProductName();
  moveBannerElements();
  wrapElementsByClass();
  insertWishlistButtonIntoThumbnail();
  wrapSearchElements();
  commonChangePriceFormat(".sysRetailPrice");
  MicroModal.init();
  if (window.matchMedia && window.matchMedia("(min-width: 768px)").matches) {
    categorySearchSlider();
  }
}
// ポタリングバイク一覧（静的）ページ 処理実行
if (document.getElementById("potterringbike")) {
  potterRingBikePage();
}

// ポタリングバイク一覧（静的）ページ 処理実行
if (document.getElementById("kickboard")) {
  kickBoardPage();
}

import "./react/top";
import { commonDraggableHandler } from "./namespace/common/commonDraggableHandler";

// web-vitalsコンソール出力 production or development
// const environment = process.env.NODE_ENV || "production";
