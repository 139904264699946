import React from "react";

// api
import { getProductData } from "../api/getArticleData";
import { formatDate } from "../utils/formatDate";

function RentalComponent() {
  const api_detail =
    "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/rentalspot?_embed";
  const { data } = getProductData(api_detail);
  // console.log(data, "data");
  // const htmlContent = data[0].content.rendered;

  return (
    <div>     
      <div className="p-top__section">
        <div className="p-top-rental__section">
            <div className="p-top-rental__wrapper">
            {/* <h2 className="p-top-rental__headingLv2">Daytona Potteringbikeを借りて<br aria-hidden="true"></br>ポタリングを体験しよう<span>ポタリングを体験しよう<br aria-hidden="true"></br>ダミーコピーです。<br aria-hidden="true"></br>ダミーです。ダミーコピーです。</span></h2> */}
            <h2 className="p-top-rental__headingLv2">Daytona Mobilityを借りて<br aria-hidden="true"></br>ポタリングを体験しよう</h2>
              <ul className="c-blog__row1-list">
                {data.slice(0, 4).map((post, index) => (
                  <li key={index} className="c-blog__item">
                    <a className="c-blog__link" href={post.link}>
                      <picture className="c-blog__img">
                        {/* <img src={post._embedded?.['wp:featuredmedia']?.[0]?.source_url} alt={post.title.rendered} width="150" height="150" /> */}
                        {/* <img src={post._embedded?.['wp:featuredmedia']?.[0]?.media_details?.sizes?.thumbnail?.source_url} alt={post.title.rendered} width="150" height="150" /> */}
                        {post.list_thumbnail 
                          ? <img src={post.list_thumbnail} alt={post.title.rendered} width="150" height="150" />
                          : <img src={"https://docs.google.com/spreadsheets/d/1FWy3Sjcf0B68cxSgHJzTrfZAcpzW1El-5itdhEc_wsE/edit#gid=67417497"} alt={post.title.rendered} width="150" height="150" />
                        }
                      </picture>
                      <p className="c-blog__label">{post.rentalspot_term_names}</p>
                      <div className="c-blog__text-wrapper">
                        {/* <p className="c-blog__text">{post.introduction} </p> */}
                        <p className="c-blog__text">{post.title.rendered} </p>
                      </div>
                    </a>
                  </li>
                  
                ))}
              </ul>
              <div className="c-btn-wrapper">
                <a href="/contents/rentalspot/" className="c-btn">レンタサイクルスポットをもっと見る</a>
              </div>
          </div>            
        </div>            
      </div>
    </div>
  );
}

export default RentalComponent;


[
	{
		"id": 1087,
		"date": "2023-07-14T16:25:46",
		"date_gmt": "2023-07-14T07:25:46",
		"guid": {
			"rendered": "https://mobility.daytona.co.jp/contents/?post_type=rentalspot&#038;p=1087"
		},
		"modified": "2023-07-19T17:59:24",
		"modified_gmt": "2023-07-19T08:59:24",
		"slug": "spot005",
		"status": "publish",
		"type": "rentalspot",
		"link": "http://mobility.daytona.co.jp/contents/rentalspot/hokkaido_tohoku/hokkaido/spot005/",
		"title": {
			"rendered": "ANDOOR"
		},
		"featured_media": 1138,
		"template": "",
		"list_thumbnail": "https://mobility.daytona.co.jp/contents/wp-content/uploads/2023/07/andoor_pic_04.jpg",
		"introduction": "北海道河西郡更別村：ANDORとかち帯広空港からの手ぶらレンタサイクルが好評です。空港周辺には十勝ならではの田園風景、有名ガーデンなど見どころがたくさん。特に空港からすぐの「ばんえい牧場十勝」では世界で唯一、帯広でしか見ることができないばん馬を飼育。ばん馬の親子の可愛らしい姿に癒やされます。 おしゃれで楽に走れるDE01でぜひ素敵な思い出を作って下さい。 ",
		"rentalspot_term_names": [
			"北海道"
		],
		"_links": {
			"self": [
				{
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/rentalspot/1087"
				}
			],
			"collection": [
				{
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/rentalspot"
				}
			],
			"about": [
				{
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/types/rentalspot"
				}
			],
			"wp:featuredmedia": [
				{
					"embeddable": true,
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/media/1138"
				}
			],
			"wp:attachment": [
				{
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/media?parent=1087"
				}
			],
			"curies": [
				{
					"name": "wp",
					"href": "https://api.w.org/{rel}",
					"templated": true
				}
			]
		},
		"_embedded": {
			"wp:featuredmedia": [
				{
					"code": "rest_forbidden",
					"message": "その操作を実行する権限がありません。",
					"data": {
						"status": 401
					}
				}
			]
		}
	},
	{
		"id": 770,
		"date": "2023-07-10T17:07:04",
		"date_gmt": "2023-07-10T08:07:04",
		"guid": {
			"rendered": "https://mobility.daytona.co.jp/contents/?post_type=rentalspot&#038;p=770"
		},
		"modified": "2023-07-19T18:09:25",
		"modified_gmt": "2023-07-19T09:09:25",
		"slug": "%e5%9c%b0%e8%94%b5%e3%82%b5%e3%82%a4%e3%82%af%e3%83%ab",
		"status": "publish",
		"type": "rentalspot",
		"link": "http://mobility.daytona.co.jp/contents/rentalspot/hokkaido_tohoku/hokkaido/%E5%9C%B0%E8%94%B5%E3%82%B5%E3%82%A4%E3%82%AF%E3%83%AB/",
		"title": {
			"rendered": "地蔵サイクル"
		},
		"featured_media": 775,
		"template": "",
		"list_thumbnail": "https://mobility.daytona.co.jp/contents/wp-content/uploads/2023/07/Jizo_pic_01.jpg",
		"introduction": "北海道旭川市のちょっと北、上川北部地方のおすすめの風景と食を地元ガイドと一緒に巡ってみませんか？1名様からOKのプライベートツアー。鉄道好きの方向けのツアーもご用意しています。詳細はWEBページまたはお電話にてお問い合わせください。",
		"rentalspot_term_names": [
			"北海道"
		],
		"_links": {
			"self": [
				{
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/rentalspot/770"
				}
			],
			"collection": [
				{
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/rentalspot"
				}
			],
			"about": [
				{
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/types/rentalspot"
				}
			],
			"wp:featuredmedia": [
				{
					"embeddable": true,
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/media/775"
				}
			],
			"wp:attachment": [
				{
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/media?parent=770"
				}
			],
			"curies": [
				{
					"name": "wp",
					"href": "https://api.w.org/{rel}",
					"templated": true
				}
			]
		},
		"_embedded": {
			"wp:featuredmedia": [
				{
					"id": 775,
					"date": "2023-07-11T10:55:46",
					"slug": "jizo_pic_01",
					"type": "attachment",
					"link": "https://mobility.daytona.co.jp/contents/jizo_pic_01/",
					"title": {
						"rendered": "Jizo_pic_01"
					},
					"author": 4,
					"caption": {
						"rendered": ""
					},
					"alt_text": "",
					"media_type": "image",
					"mime_type": "image/jpeg",
					"media_details": {
						"width": 500,
						"height": 300,
						"file": "2023/07/Jizo_pic_01.jpg",
						"filesize": 128184,
						"sizes": {
							"medium": {
								"file": "Jizo_pic_01-300x180.jpg",
								"width": 300,
								"height": 180,
								"filesize": 14086,
								"mime_type": "image/jpeg",
								"source_url": "https://mobility.daytona.co.jp/contents/wp-content/uploads/2023/07/Jizo_pic_01-300x180.jpg"
							},
							"full": {
								"file": "Jizo_pic_01.jpg",
								"width": 500,
								"height": 300,
								"mime_type": "image/jpeg",
								"source_url": "https://mobility.daytona.co.jp/contents/wp-content/uploads/2023/07/Jizo_pic_01.jpg"
							}
						},
						"image_meta": {
							"aperture": "0",
							"credit": "",
							"camera": "",
							"caption": "",
							"created_timestamp": "0",
							"copyright": "",
							"focal_length": "0",
							"iso": "0",
							"shutter_speed": "0",
							"title": "",
							"orientation": "0",
							"keywords": []
						}
					},
					"source_url": "https://mobility.daytona.co.jp/contents/wp-content/uploads/2023/07/Jizo_pic_01.jpg",
					"_links": {
						"self": [
							{
								"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/media/775"
							}
						],
						"collection": [
							{
								"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/media"
							}
						],
						"about": [
							{
								"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/types/attachment"
							}
						],
						"author": [
							{
								"embeddable": true,
								"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/users/4"
							}
						],
						"replies": [
							{
								"embeddable": true,
								"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/comments?post=775"
							}
						]
					}
				}
			]
		}
	},
	{
		"id": 769,
		"date": "2023-07-10T16:59:06",
		"date_gmt": "2023-07-10T07:59:06",
		"guid": {
			"rendered": "https://mobility.daytona.co.jp/contents/?post_type=rentalspot&#038;p=769"
		},
		"modified": "2023-07-19T17:43:37",
		"modified_gmt": "2023-07-19T08:43:37",
		"slug": "spot0013",
		"status": "publish",
		"type": "rentalspot",
		"link": "http://mobility.daytona.co.jp/contents/rentalspot/chubu/aichi/spot0013/",
		"title": {
			"rendered": "ぽたび（東栄町観光まちづくり協会）"
		},
		"featured_media": 779,
		"template": "",
		"list_thumbnail": "https://mobility.daytona.co.jp/contents/wp-content/uploads/2023/07/Machinaka_pic_02.jpg",
		"introduction": " 山間の自然あふれるまちをゆったりとめぐる、ポタリング旅をお楽しみいただけます。おすすめのお店や季節の観光スポットなども地元スタッフがご案内します。  ・ツアーについての紹介 　地元ガイドによるローカルな視点で暮らしに触れ、季節に合わせた食や体験を織りまぜた電動アシスト自転車のレンタル付きガイドツアー。参加者のリクエストや季節に合わせてコースをカスタマイズし、オンリーワンのまちめぐりの旅へご案内。大自然のロケーションや景色その日、その時だけの出会いを演出します。 ・ツアーで巡る場所 　東栄町のおすすめローカルスポットや自然スポット、地域のお店や面白い人を紹介。  WEBサイト：https://potabi-toei.com/guide-tour SNS：https://www.instagram.com/potabi_toei/  東栄町は、いわゆる観光地のような場所ではありませんが、町に住む皆さんの魅力的な日常に触れる“ローカルな旅”の楽しみを提供していますので、ぜひ体験してみてください。",
		"rentalspot_term_names": [
			"愛知県"
		],
		"_links": {
			"self": [
				{
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/rentalspot/769"
				}
			],
			"collection": [
				{
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/rentalspot"
				}
			],
			"about": [
				{
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/types/rentalspot"
				}
			],
			"wp:featuredmedia": [
				{
					"embeddable": true,
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/media/779"
				}
			],
			"wp:attachment": [
				{
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/media?parent=769"
				}
			],
			"curies": [
				{
					"name": "wp",
					"href": "https://api.w.org/{rel}",
					"templated": true
				}
			]
		},
		"_embedded": {
			"wp:featuredmedia": [
				{
					"id": 779,
					"date": "2023-07-11T11:09:53",
					"slug": "machinaka_pic_02",
					"type": "attachment",
					"link": "https://mobility.daytona.co.jp/contents/machinaka_pic_02/",
					"title": {
						"rendered": "Machinaka_pic_02"
					},
					"author": 4,
					"caption": {
						"rendered": ""
					},
					"alt_text": "",
					"media_type": "image",
					"mime_type": "image/jpeg",
					"media_details": {
						"width": 500,
						"height": 300,
						"file": "2023/07/Machinaka_pic_02.jpg",
						"filesize": 242861,
						"sizes": {
							"medium": {
								"file": "Machinaka_pic_02-300x180.jpg",
								"width": 300,
								"height": 180,
								"filesize": 25305,
								"mime_type": "image/jpeg",
								"source_url": "https://mobility.daytona.co.jp/contents/wp-content/uploads/2023/07/Machinaka_pic_02-300x180.jpg"
							},
							"full": {
								"file": "Machinaka_pic_02.jpg",
								"width": 500,
								"height": 300,
								"mime_type": "image/jpeg",
								"source_url": "https://mobility.daytona.co.jp/contents/wp-content/uploads/2023/07/Machinaka_pic_02.jpg"
							}
						},
						"image_meta": {
							"aperture": "0",
							"credit": "",
							"camera": "",
							"caption": "",
							"created_timestamp": "0",
							"copyright": "",
							"focal_length": "0",
							"iso": "0",
							"shutter_speed": "0",
							"title": "",
							"orientation": "0",
							"keywords": []
						}
					},
					"source_url": "https://mobility.daytona.co.jp/contents/wp-content/uploads/2023/07/Machinaka_pic_02.jpg",
					"_links": {
						"self": [
							{
								"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/media/779"
							}
						],
						"collection": [
							{
								"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/media"
							}
						],
						"about": [
							{
								"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/types/attachment"
							}
						],
						"author": [
							{
								"embeddable": true,
								"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/users/4"
							}
						],
						"replies": [
							{
								"embeddable": true,
								"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/comments?post=779"
							}
						]
					}
				}
			]
		}
	},
	{
		"id": 768,
		"date": "2023-07-10T16:54:45",
		"date_gmt": "2023-07-10T07:54:45",
		"guid": {
			"rendered": "https://mobility.daytona.co.jp/contents/?post_type=rentalspot&#038;p=768"
		},
		"modified": "2023-07-11T11:11:01",
		"modified_gmt": "2023-07-11T02:11:01",
		"slug": "spot002",
		"status": "publish",
		"type": "rentalspot",
		"link": "http://mobility.daytona.co.jp/contents/rentalspot/chugoku/hiroshima/spot002/",
		"title": {
			"rendered": "川西レンタルサイクル"
		},
		"featured_media": 778,
		"template": "",
		"list_thumbnail": "https://mobility.daytona.co.jp/contents/wp-content/uploads/2023/07/Kawanishi_pic_02.jpg",
		"introduction": "  広島県三次市で１dayレンタサイクルを行っています。農山村の景観やカフェめぐりをお楽しみいただけます。ぜひDE01またはDE03で散策してみてください♪ また、ポタリングガイドと一緒にまわるガイドツアーもございます！里山田園風景を楽しめるオススメコースを楽しんでみませんか？草花や人々との出会いあり、おいしいものありのコース！農山村のステキな日常とのめぐり逢いを心地よくお楽しみいただけます♪   ",
		"rentalspot_term_names": [
			"広島県"
		],
		"_links": {
			"self": [
				{
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/rentalspot/768"
				}
			],
			"collection": [
				{
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/rentalspot"
				}
			],
			"about": [
				{
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/types/rentalspot"
				}
			],
			"wp:featuredmedia": [
				{
					"embeddable": true,
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/media/778"
				}
			],
			"wp:attachment": [
				{
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/media?parent=768"
				}
			],
			"curies": [
				{
					"name": "wp",
					"href": "https://api.w.org/{rel}",
					"templated": true
				}
			]
		},
		"_embedded": {
			"wp:featuredmedia": [
				{
					"id": 778,
					"date": "2023-07-11T11:09:48",
					"slug": "kawanishi_pic_02",
					"type": "attachment",
					"link": "https://mobility.daytona.co.jp/contents/kawanishi_pic_02/",
					"title": {
						"rendered": "Kawanishi_pic_02"
					},
					"author": 4,
					"caption": {
						"rendered": ""
					},
					"alt_text": "",
					"media_type": "image",
					"mime_type": "image/jpeg",
					"media_details": {
						"width": 500,
						"height": 300,
						"file": "2023/07/Kawanishi_pic_02.jpg",
						"filesize": 143697,
						"sizes": {
							"medium": {
								"file": "Kawanishi_pic_02-300x180.jpg",
								"width": 300,
								"height": 180,
								"filesize": 19178,
								"mime_type": "image/jpeg",
								"source_url": "https://mobility.daytona.co.jp/contents/wp-content/uploads/2023/07/Kawanishi_pic_02-300x180.jpg"
							},
							"full": {
								"file": "Kawanishi_pic_02.jpg",
								"width": 500,
								"height": 300,
								"mime_type": "image/jpeg",
								"source_url": "https://mobility.daytona.co.jp/contents/wp-content/uploads/2023/07/Kawanishi_pic_02.jpg"
							}
						},
						"image_meta": {
							"aperture": "0",
							"credit": "",
							"camera": "",
							"caption": "",
							"created_timestamp": "0",
							"copyright": "",
							"focal_length": "0",
							"iso": "0",
							"shutter_speed": "0",
							"title": "",
							"orientation": "0",
							"keywords": []
						}
					},
					"source_url": "https://mobility.daytona.co.jp/contents/wp-content/uploads/2023/07/Kawanishi_pic_02.jpg",
					"_links": {
						"self": [
							{
								"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/media/778"
							}
						],
						"collection": [
							{
								"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/media"
							}
						],
						"about": [
							{
								"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/types/attachment"
							}
						],
						"author": [
							{
								"embeddable": true,
								"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/users/4"
							}
						],
						"replies": [
							{
								"embeddable": true,
								"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/comments?post=778"
							}
						]
					}
				}
			]
		}
	},
	{
		"id": 765,
		"date": "2023-07-10T16:43:49",
		"date_gmt": "2023-07-10T07:43:49",
		"guid": {
			"rendered": "https://mobility.daytona.co.jp/contents/?post_type=rentalspot&#038;p=765"
		},
		"modified": "2023-07-19T19:37:59",
		"modified_gmt": "2023-07-19T10:37:59",
		"slug": "spot001",
		"status": "publish",
		"type": "rentalspot",
		"link": "http://mobility.daytona.co.jp/contents/rentalspot/chugoku/hiroshima/spot001/",
		"title": {
			"rendered": "LORO尾道 レンタサイクル"
		},
		"featured_media": 780,
		"template": "",
		"list_thumbnail": "https://mobility.daytona.co.jp/contents/wp-content/uploads/2023/07/LORO-pic_03.jpg",
		"introduction": "広島県尾道市：LORO尾道 レンタサイクル  尾道駅から徒歩5分、サイクリストの聖地「しまなみ海道」へすぐにアクセス出来る場所に当店があります。最高のロケーションを専門スタッフが整備をしたDaytonaPotteringBikeで快適なサイクリングをお楽しみ下さいませ。",
		"rentalspot_term_names": [
			"広島県"
		],
		"_links": {
			"self": [
				{
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/rentalspot/765"
				}
			],
			"collection": [
				{
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/rentalspot"
				}
			],
			"about": [
				{
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/types/rentalspot"
				}
			],
			"wp:featuredmedia": [
				{
					"embeddable": true,
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/media/780"
				}
			],
			"wp:attachment": [
				{
					"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/media?parent=765"
				}
			],
			"curies": [
				{
					"name": "wp",
					"href": "https://api.w.org/{rel}",
					"templated": true
				}
			]
		},
		"_embedded": {
			"wp:featuredmedia": [
				{
					"id": 780,
					"date": "2023-07-11T11:09:58",
					"slug": "loro-pic_03",
					"type": "attachment",
					"link": "https://mobility.daytona.co.jp/contents/loro-pic_03/",
					"title": {
						"rendered": "LORO-pic_03"
					},
					"author": 4,
					"caption": {
						"rendered": ""
					},
					"alt_text": "",
					"media_type": "image",
					"mime_type": "image/jpeg",
					"media_details": {
						"width": 500,
						"height": 300,
						"file": "2023/07/LORO-pic_03.jpg",
						"filesize": 193088,
						"sizes": {
							"medium": {
								"file": "LORO-pic_03-300x180.jpg",
								"width": 300,
								"height": 180,
								"filesize": 17391,
								"mime_type": "image/jpeg",
								"source_url": "https://mobility.daytona.co.jp/contents/wp-content/uploads/2023/07/LORO-pic_03-300x180.jpg"
							},
							"full": {
								"file": "LORO-pic_03.jpg",
								"width": 500,
								"height": 300,
								"mime_type": "image/jpeg",
								"source_url": "https://mobility.daytona.co.jp/contents/wp-content/uploads/2023/07/LORO-pic_03.jpg"
							}
						},
						"image_meta": {
							"aperture": "0",
							"credit": "",
							"camera": "",
							"caption": "",
							"created_timestamp": "0",
							"copyright": "",
							"focal_length": "0",
							"iso": "0",
							"shutter_speed": "0",
							"title": "",
							"orientation": "0",
							"keywords": []
						}
					},
					"source_url": "https://mobility.daytona.co.jp/contents/wp-content/uploads/2023/07/LORO-pic_03.jpg",
					"_links": {
						"self": [
							{
								"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/media/780"
							}
						],
						"collection": [
							{
								"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/media"
							}
						],
						"about": [
							{
								"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/types/attachment"
							}
						],
						"author": [
							{
								"embeddable": true,
								"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/users/4"
							}
						],
						"replies": [
							{
								"embeddable": true,
								"href": "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/comments?post=780"
							}
						]
					}
				}
			]
		}
	}
]