import Splide from "@splidejs/splide";
import "@splidejs/splide/css";
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

function commonAccordion(): void {
  const trigger = document.querySelectorAll(".js-accordion-trigger");
  const triggerArr = Array.prototype.slice.call(trigger);
  for (let i = 0; i < triggerArr.length; i++) {
    triggerArr[i].addEventListener("click", function() {
      const parent = this.parentNode;
      const body = parent.nextElementSibling;
      if (this.classList.contains("is-accordion-active")) {
        this.classList.remove("is-accordion-active");
        parent.classList.remove("is-accordion-active"); // Parent class removal
        this.setAttribute("title", "メニューを開く");
        this.setAttribute("title", "メニューを開く");
        this.setAttribute("aria-selected", false);
        this.setAttribute("aria-expanded", false);
        body.classList.remove("is-accordion-open");
        body.setAttribute("aria-hidden", true);
        body.setAttribute("tabindex", -1);
      } else {
        this.classList.add("is-accordion-active");
        parent.classList.add("is-accordion-active"); // Parent class addition
        this.setAttribute("title", "メニューを閉じる");
        this.setAttribute("aria-selected", true);
        this.setAttribute("aria-expanded", true);
        body.classList.add("is-accordion-open");
        body.setAttribute("aria-hidden", false);
        body.setAttribute("tabindex", 0);
      }
    });
  }
}

function topSlider(): void {
  document.addEventListener( 'DOMContentLoaded', function() {
    var mvSplide = new Splide( '.mvSplide', {
      type : 'loop',
      autoplay: true,
      pagination: false,
      pauseOnHover: false,
      speed: 1200
    } );

    var speechSplide = new Splide( '.speechSplide', {
      type : 'loop',
      autoplay: true,
      pagination: false,
      arrows: false,
      pauseOnHover: false,
    } );

    mvSplide.mount();
    speechSplide.mount();
  } );
}

function productTabChange(): void {
  const tabList = document.querySelector(".p-top-product-navi__list");
  const activeIndicator = document.createElement("li");
  activeIndicator.classList.add("active-indicator");
  tabList.appendChild(activeIndicator);

  // Get all the tabs and contents
  const tabs = Array.from(
    document.querySelectorAll(".p-top-product-navi__button")
  );
  const contents = Array.from(
    document.querySelectorAll(".p-top-product-navi__content")
  );

  // Function to deactivate all tabs and hide all contents
  function resetTabs() {
    tabs.forEach(tab => tab.classList.remove("is-active"));
    contents.forEach(content => content.classList.remove("is-active"));
  }

  // Add click event listeners to tabs
  tabs.forEach(tab => {
    tab.addEventListener("click", () => {
      // Deactivate all tabs and hide all contents
      resetTabs();

      // Get the data-index of clicked tab
      const dataIndex = tab.getAttribute("data-index");

      // Activate clicked tab
      tab.classList.add("is-active");

      // Show content corresponding to clicked tab
      const content = document.querySelector(
        `.p-top-product-navi__content[data-index="${dataIndex}"]`
      );
      if (content) content.classList.add("is-active");

      // Update the position and width of the active indicator
      const rect = tab.getBoundingClientRect();
      let leftOffset = rect.left - tabList.getBoundingClientRect().left;
      let widthOffset = rect.width;

      // Shift the active indicator
      if (dataIndex === "1") {
        leftOffset += 4;
        widthOffset -= 4;
      } else if (dataIndex === "3") {
        widthOffset -= 4;
      }

      activeIndicator.style.left = `${leftOffset}px`;
      activeIndicator.style.width = `${widthOffset}px`;
    });

    // Initialize the position and width of the active indicator
    const initialActiveTab = document.querySelector(
      ".p-top-product-navi__button.is-active"
    );
    if (initialActiveTab) {
      const rect = initialActiveTab.getBoundingClientRect();
      let leftOffset = rect.left - tabList.getBoundingClientRect().left;
      let widthOffset = rect.width;

      // Shift the active indicator
      const dataIndex = initialActiveTab.getAttribute("data-index");
      if (dataIndex === "1") {
        leftOffset += 4;
        widthOffset -= 4;
      } else if (dataIndex === "3") {
        widthOffset -= 4;
      }

      activeIndicator.style.left = `${leftOffset}px`;
      activeIndicator.style.width = `${widthOffset}px`;
    }
  });
}

function topColorListData(): void {
  const colorItems = document.querySelectorAll(".p-top-product__color-item");

  colorItems.forEach((item: Element) => {
    const color = item.getAttribute("data-color");
    if (color !== null) {
      (item as HTMLElement).style.backgroundColor = color;
    }
  });
}

function topAboutAutoSlider(): void {
  document.addEventListener('DOMContentLoaded', function() {
    if (window.innerWidth <= 767 && document.querySelector('.splide-about-slider')) {
      var splideAboutSlider = new Splide('.splide-about-slider', {
        type: 'loop',
        fixedWidth: '155px',
        gap: '5px',
        arrows: false,
        pagination: false,
        drag: false,
        autoScroll: {
          speed: 0.5,
          autoStart: true,
          pauseOnHover: false,
          pauseOnFocus: false,
        },
      });

      splideAboutSlider.mount({ AutoScroll });
    }
  });
}


export { commonAccordion, topSlider, productTabChange, topColorListData, topAboutAutoSlider };

