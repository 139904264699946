import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export const kickBoardPage = () => {
  function gsapAnimation() {
    if (window.innerWidth <= 768) {
      gsap.registerPlugin(ScrollTrigger);

      // モバイルデバイスでのみスクロールトリガーを設定
      const listWrapperEl = document.querySelector(
        ".js-horizontal-scroll-section"
      );
      const listEl = document.querySelector(".js-horizontal-scroll-content");

      if (!listWrapperEl || !listEl) {
        console.error("Required elements not found");
        return;
      }

      gsap.to(listEl, {
        x: () => -(listEl.clientWidth - listWrapperEl.clientWidth),
        ease: "none",
        scrollTrigger: {
          trigger: ".js-horizontal-scroll-section",
          start: "top 10%",
          end: () => `+=${listEl.clientWidth - listWrapperEl.clientWidth}`,
          scrub: true,
          pin: true
        }
      });
    }
  }

  function floatBtn() {
    // intersection
    const floatBtn = document.querySelector(".js-float");
    const mvSection = document.querySelector(".js-mv");
    const footerSection = document.getElementById("sysFooter");
    if (!floatBtn) return;

    const primaryObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          floatBtn.setAttribute("aria-hidden", "false");
        } else {
          floatBtn.setAttribute("aria-hidden", "true");
        }
      });
    });

    const secondaryObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          floatBtn.setAttribute("aria-hidden", "true");
        } else {
          floatBtn.setAttribute("aria-hidden", "false");
        }
      });
    });

    primaryObserver.observe(mvSection);
    secondaryObserver.observe(footerSection);
  }

  gsapAnimation();
  floatBtn();
};
