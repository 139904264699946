import React from "react";

// api
import { getProductData } from "../api/getArticleData";
import { formatDate } from "../utils/formatDate";

function MediaComponent() {
  const api_detail =
    "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/publication?_embed";
  const { data } = getProductData(api_detail);
  // console.log(data, "data");
  // const htmlContent = data[0].content.rendered;

  return (
    <div>     
      <div className="p-top__section">
        <div className="p-top-topic__wrapper">
          <h2 className="p-top__headingLv2">メディア掲載</h2>
          <ul className="c-blog__row1-list">
            {data.slice(0, 4).map((post, index) => (
              <li key={index} className="c-blog__item">
                <a className="c-blog__link" href={post.link}>
                  <picture className="c-blog__img">                    
                    {/* <img src={post._embedded?.['wp:featuredmedia']?.[0]?.media_details?.sizes?.thumbnail?.source_url} alt={post.title.rendered} width="150" height="150" /> */}
                    {post.list_thumbnail 
                      ? <img src={post.list_thumbnail} alt={post.title.rendered} width="150" height="150" />
                      : <img src={"https://mobility.daytona.co.jp/img/wp/noimage.jpg"} alt={post.title.rendered} width="150" height="150" />
                    }
                  </picture>
                  <div className="c-blog__text-wrapper">
                     {/* <p className="c-blog__text">{post.content.rendered} </p> */}
                     <p className="c-blog__text">{post.title.rendered} </p>
                  </div>
                </a>
              </li>
              
            ))}
          </ul>
					<div className="c-btn-wrapper">
						<a href="/contents/publication/" className="c-btn">メディア掲載をもっと見る</a>
					</div>
        </div>            
      </div>
    </div>
  );
}

export default MediaComponent;

