import React from "react";

// api
import { getProductData } from "../api/getArticleData";
import { formatDate } from "../utils/formatDate";

function KickBoardMediaComponent() {
  const api_detail =
    "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/publication?_embed";
  const { data } = getProductData(api_detail);      

   return (
     <div>     
       <div className="p-top__section">
         <div className="p-top-topic__wrapper u-mt50">
           <h2 className="p-top__headingLv2">メディア掲載</h2>
           <ul className="c-blog__row1-list">
             {data.slice(0, 4).map((post, index) => {
              if (post.model_number[0] === "DK01") {
                return (
                  <li key={index} className="c-blog__item">
                    <a className="c-blog__link" href={post.link}>
                      <picture className="c-blog__img">
                        {post.list_thumbnail 
                          ? <img src={post.list_thumbnail} alt={post.title.rendered} width="150" height="150" />
                          : <img src={"https://mobility.daytona.co.jp/img/wp/noimage.jpg"} alt={post.title.rendered} width="150" height="150" />
                        }
                      </picture>
                      <div className="c-blog__text-wrapper">
                          <p className="c-blog__text">{post.title.rendered} </p>
                      </div>
                    </a>
                  </li>
                )
              }
              })}
           </ul>           
         </div>            
       </div>
     </div>
   );
 }
 
 export default KickBoardMediaComponent;

