import axios from "axios";
import useSWR, { SWRConfiguration } from "swr";

//anyはのちに型を指定する
export const fetcher = async (url: string): Promise<any> => {
  try {
    const res = await axios.get(url);

    const { data } = res;
    return data;
  } catch (error) {
    console.log(error);
    // throw Error("商品情報の取得に失敗しました");
    throw error;
  }
};

const options: SWRConfiguration = {
  revalidateIfStale: false,
  revalidateOnFocus: true,
  revalidateOnReconnect: false,
  suspense: true
};

//セキュリティのため、APIのURLは.envファイルに記載する？？
// const api_detail =
//   "https://mobility.daytona.co.jp/contents/wp-json/wp/v2/posts?_embed";

export const getProductData = (apiUrl: string): any => {
  const { data, error } = useSWR<any>(apiUrl, fetcher, options);

  // console.log(data);

  return {
    data,
    error,
    isLoading: !error && !data
  };
};
